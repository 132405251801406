import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const burgerworksProduct: IConfigsType = {
  burgerworks: {
    '/:brand/sales_mix': {
      variables: [
        // Hardcoded
        {
          type: 'items',
          root: 'EXPO_MENU_CATEGORY_ROOT',
          initialSelection: ['404', 'category-404'],
        },
      ],
      groupFilter: {
        api: 'corporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: [
          'period',
          'quarter',
          'year',
          'custom',
          'week',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
        ],
      },
      title: {
        type: 'title',
        title: 'Sales Mix',
      },
      filters: {
        type: 'grid',
        children: {
          item: {
            type: 'select',
            value: 'items.value',
            onChange: 'items.onChange',
            options: 'items.options',
            label: 'Item or Category',
          },
        },
      },
      matrix: {
        type: 'bubbleWithLabels-chart',
        width: 1200,
        height: 600,
        api: 'item',
        datasets: [],
        datasetConfig: {
          x: 'totalSales',
          xType: 'price',
          xDecimal: 0,
          y: 'averageUnitPrice',
          yType: 'price',
          yDecimal: 2,
          r: 'quantitySold',
          label: 'itemName',
        },
        label: 'itemName',
        scaleTypes: {
          y: {
            type: 'price',
            decimal: 2,
          },
          x: {
            type: 'price',
          },
        },
        options: {
          scales: {
            y: {
              title: {
                text: 'Avg Price',
              },
            },
            x: {
              title: {
                text: 'Total Sales',
              },
            },
          },
        },
      },
      salesMixTable: {
        type: 'table-v2',
        api: 'item',
        row: { background: '<%- index % 2 ? colors.Porcelain : "" %>' },
        pagination: {
          pageSize: 20,
        },
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            sortable: true,
          },
          {
            key: 'averageUnitPrice',
            title: 'Avg Price',
            align: 'center',
            sortable: true,
            width: '125px',
            decimal: 2,
          },
          {
            key: 'quantitySold',
            title: 'Quantity Sold',
            align: 'center',
            sortable: true,
            width: '125px',
          },
          {
            key: 'totalSales',
            title: 'Item Sales',
            align: 'center',
            sortable: true,
            width: '125px',
          },
        ],
      },
      historicalPerformance: {
        type: 'line-chart',
        api: 'itemTrend',
        width: 1200,
        height: 300,
        label: 'dateRangeLabel',
        datasets: [
          {
            key: 'totalSales',
            label: 'Total Sales',
          },
          {
            chartType: 'bar',
            key: 'quantitySold',
            label: 'Quantity Sold',
            yAxisID: 'y1',
            backgroundColor: COLORS.Mango,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
          y1: {
            type: 'number',
          },
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                text: 'Total Sales',
              },
            },
            y1: {
              title: {
                text: 'Quantity Sold',
                display: true,
              },
              position: 'right',
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      },
    },
    '/:brand/item_by_daypart': {
      variables: [
        // Hardcoded
        {
          type: 'items',
          root: 'EXPO_MENU_CATEGORY_ROOT',
          initialSelection: ['404', 'category-404'],
        },
        'itemByDaypartColumnFilter',
      ],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
        ],
      },
      title: {
        type: 'title',
        title: 'Items by Daypart',
      },
      filters: {
        type: 'grid',
        children: {
          item: {
            type: 'select',
            value: 'items.value',
            onChange: 'items.onChange',
            options: 'items.options',
            label: 'Item',
          },
          unit: {
            type: 'select',
            value: 'itemByDaypartColumnFilter.value',
            onChange: 'itemByDaypartColumnFilter.onChange',
            options: 'itemByDaypartColumnFilter.options',
            label: 'Unit',
          },
        },
      },
      itemByDaypart: {
        type: 'table-v2',
        api: 'itemByDaypart',
        columns: [
          {
            key: 'locationName',
            title: 'Name',
            sortable: true,
          },
          {
            key: 'itemOrCategoryName',
            title: 'Item',
            align: 'center',
            sortable: true,
          },
          {
            key: 'earlyBird.percent',
            title: 'Early Bird',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'earlyBird.quantity',
            title: 'Early Bird',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'breakfast.percent',
            title: 'Breakfast',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'breakfast.quantity',
            title: 'Breakfast',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'lunch.percent',
            title: 'Lunch',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'lunch.quantity',
            title: 'Lunch',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'afternoonSnack.percent',
            title: 'Afternoon Snack',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'afternoonSnack.quantity',
            title: 'Afternoon Snack',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'dinner.percent',
            title: 'Dinner',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'dinner.quantity',
            title: 'Dinner',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'eveningSnack.percent',
            title: 'Evening Snack',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'eveningSnack.quantity',
            title: 'Evening Snack',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
        ],
      },
    },
    '/:brand/item_velocity': {
      variables: [
        {
          type: 'items',
          root: 'EXPO_MENU_CATEGORY_ROOT',
          initialSelection: ['404', 'category-404'],
        },
        'interval',
        'timeBetween',
      ],
      groupFilter: {
        api: 'companyFilter',
      },
      dateFilter: {
        types: [
          'week',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
        ],
      },
      title: {
        type: 'title',
        title: 'Item Velocity',
      },
      filters: {
        type: 'grid',
        children: {
          items: {
            type: 'select',
            value: 'items.value',
            onChange: 'items.onChange',
            options: 'items.options',
            label: 'Show',
          },
          intervals: {
            type: 'select',
            value: 'interval.value',
            onChange: 'interval.onChange',
            options: 'interval.options',
            label: 'Time Interval',
          },
          timeBetween: {
            type: 'select',
            value: 'timeBetween.value',
            onChange: 'timeBetween.onChange',
            options: 'timeBetween.options',
            label: 'Minutes Between Orders',
          },
        },
      },
      itemVelocitySummary: {
        type: 'list',
        api: 'madeFromScratchSummary',
        fields: [
          {
            width: '150px',
            content: {
              key: 'value',
            },
            bottom: 'Total Incidences',
          },
          {
            width: '200px',
            content: {
              key: 'percentScratch',
              decimal: 1,
            },
            bottom: '% Affected Customers who ordered Item',
          },
        ],
      },
      itemVelocityCsv: {
        type: 'download-csv',
        api: 'madeFromScratch',
        message: 'Download as CSV',
        fileName: 'getexpo-item-velocity',
        fields: [
          {
            key: 'daypart',
            title: 'Time',
          },
          {
            key: 'value',
            title: 'Made From Scratch #',
          },
          {
            key: 'percentScratch',
            title: 'Made From Scratch %',
          },
          {
            key: 'total',
            title: 'Total Times Ordered',
          },
        ],
      },
      itemVelocity: {
        type: 'table-v2',
        api: 'madeFromScratch',
        columns: [
          {
            key: 'daypart',
            title: 'Time',
            sortable: true,
            background:
              '<%- values?.daypart === "Average Per Day" ? "#57AFE7" : "initial" %>',
          },
          {
            key: 'madeFromScratch',
            title: 'Made From Scratch',
            align: 'center',
            background:
              '<%- values?.daypart === "Average Per Day" ? "#57AFE7" : "initial" %>',
            children: [
              {
                key: 'value',
                title: '#',
                sortable: true,
                align: 'center',
              },
              {
                key: 'percentScratch',
                title: '%',
                sortable: true,
                align: 'center',
                decimal: 1,
              },
            ],
          },
          {
            key: 'total',
            title: 'Total Times Ordered',
            sortable: true,
            align: 'center',
            background:
              '<%- values?.daypart === "Average Per Day" ? "#57AFE7" : "initial" %>',
          },
        ],
      },
    },
  },
}

export default burgerworksProduct
