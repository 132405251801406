import { IRouteType } from '../../types'
import burgerworksCorporateSalesmanshipConfig from './burgerworksCorporateSalesmanship'
import burgerworksDeliveryConfig from './burgerworksDelivery'
import burgerworksExpoAi from './burgerworksExpoAi'
import burgerworksFlash from './burgerworksFlash'
import burgerworksGuest from './burgerworksGuest'
import burgerworksHome from './burgerworksHome'
import burgerworksInventory from './burgerworksInventory'
import burgerworksLocationSalesmanshipConfig from './burgerworksLocationSalesmanship'
import burgerworksLossPreventionConfig from './burgerworksLossPrevention'
import burgerworksProduct from './burgerworksProduct'
import burgerworksRankingsConfig from './burgerworksRankings'
import burgerworksStorePerformance from './burgerworksStorePerformance'
import burgerworksSystemwideOverview from './burgerworksSystemwideOverview'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Expo AI',
        link: '/:brand/expo_ai',
      },
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        default: true,
      },
      {
        title: 'Rankings',
        link: '/:brand/rankings',
      },
      {
        title: 'Flash',
        link: '/:brand/flash',
      },
      {
        title: 'Salesmanship',
        link: '/:brand/corporate_salesmanship',
      },
      {
        title: 'Loss Prevention',
        link: '/:brand/corporate_loss_prevention',
      },
      {
        title: 'Inventory',
        link: '/:brand/corporate_inventory',
      },
      {
        title: 'Guest',
        link: '/:brand/corporate_guest',
      },
      {
        title: 'Delivery',
        link: '/:brand/corporate_delivery',
      },
      {
        title: 'Product',
        collapsible: true,
        children: [
          {
            title: 'Sales Mix',
            link: '/:brand/sales_mix',
          },
          {
            title: 'Item by Daypart',
            link: '/:brand/item_by_daypart',
          },
          {
            title: 'Item Velocity',
            link: '/:brand/item_velocity',
          },
        ],
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Home',
        link: '/:brand/scorecard',
      },
      {
        title: 'Store Performance',
        link: '/:brand/store_detail',
      },
      {
        title: 'Salesmanship',
        link: '/:brand/salesmanship',
      },
      {
        title: 'Loss Prevention',
        link: '/:brand/loss_prevention',
      },
      {
        title: 'Inventory',
        link: '/:brand/inventory',
      },
      {
        title: 'Guest',
        link: '/:brand/guest',
      },
      {
        title: 'Delivery',
        link: '/:brand/delivery',
      },
    ],
  },
]

export default [
  burgerworksSystemwideOverview,
  burgerworksRankingsConfig,
  burgerworksCorporateSalesmanshipConfig,
  burgerworksLocationSalesmanshipConfig,
  burgerworksLossPreventionConfig,
  burgerworksFlash,
  burgerworksStorePerformance,
  burgerworksDeliveryConfig,
  burgerworksHome,
  burgerworksGuest,
  burgerworksExpoAi,
  burgerworksInventory,
  burgerworksProduct,
]
